<script setup lang="ts">
import { useForm } from 'vee-validate';
import * as yup from 'yup';

// Composables
const { currentClient } = adminStore();
const { updateClient } = useClientApi();
const { t } = useI18n();

// Form
const { handleSubmit, meta } = useForm({
  validationSchema: yup.object({
    name: yup.string().required(t('global.name_is_required')),
  }),
  initialValues: {
    name: currentClient?.name,
  },
});

const submit = handleSubmit((values) => {
  if (currentClient) {
    updateClient(currentClient.id, values);
  }
});
</script>
<template>
  <ui-card :title="$t('admin.global_informations')">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-x-2">
      <ui-form-input-text :label="$t('admin.client_name')" name="name" placeholder="Superplastic" />
    </div>

    <template #footer>
      <ui-button type="submit" :color="meta.dirty ? 'primary' : 'disabled'" @click="submit">
        {{ $t('global.save') }}
      </ui-button>
    </template>
  </ui-card>
</template>
