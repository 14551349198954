<script setup lang="ts">
import type { AdminApiGetClient } from '../../../types';

// Components
import SiteModal from '../../modal/SiteModal.vue';

// Composables
const { headers, actions } = useSiteTable();
const router = useRouter();
// Stores
const { currentClient } = storeToRefs(adminStore());

// Data
const modal = ref<boolean>(false);
const selectedSite = ref<AdminApiGetClient['clients_by_pk']['sites'][0] | null>(null);

// Methods
const handleAction = (event: 'edit' | 'add' | 'view', item: AdminApiGetClient['clients_by_pk']['sites'][0] | null) => {
  switch (event) {
    case 'edit':
      selectedSite.value = item;
      modal.value = true;
      break;
    case 'add':
      selectedSite.value = null;
      modal.value = true;
      break;
    case 'view':
      router.push(`/admin/site/${item.id}`);
      break;
  }
};
</script>
<template>
  <ui-card :title="$t('admin.sites')">
    <template #header>
      <ui-button left-icon="Plus" @click="handleAction('add', null)">
        {{ $t('admin.add_site') }}
      </ui-button>
    </template>
    <ui-data-table :headers="headers" :items="currentClient?.sites ?? []" clickable @click-row="handleAction('view', $event)">
      <template #item-production_start_weekday="{ item }">
        <ui-badge color="gray"> {{ $t(`global.days_list.${item.production_start_weekday}`) }} </ui-badge>
      </template>
      <template #item-actions="{ item }">
        <ui-data-table-actions :items="actions" @click-item="handleAction($event, item)" />
      </template>
    </ui-data-table>

    <!-- Modal -->
    <site-modal v-model="modal" :site="selectedSite" :client-id="currentClient.id" />
  </ui-card>
</template>
