<script lang="ts" setup>
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import type { AdminApiGetClient } from '../../types';

// Composables
const { t } = useI18n();
const { createSite, updateSite } = useSiteApi();
const { getClient } = useClientApi();

// Props
const props = defineProps<{
  site: AdminApiGetClient['clients_by_pk']['sites'][0] | null;
  clientId: number;
}>();

// Form setup
interface FormValues {
  site_name: string;
  prm?: string;
  pce?: string;
}

const { resetForm, handleSubmit } = useForm<FormValues>({
  validationSchema: computed(() =>
    yup.object().shape({
      site_name: yup.string().required(t('global.site_name_required')),
      prm: props.site ? yup.string().optional() : yup.string().required(t('global.prm_required')),
      pce: yup.string().optional(),
    }),
  ),
  validateOnMount: false,
  initialValues: {
    site_name: props.site?.name || '',
  },
});

// Data
const modal = defineModel<boolean>();
const submiting = ref(false);

// Computed
const title = computed(() => {
  return props.site ? t('gloabal.edit_site') : t('gloabal.create_site');
});

// Methods
const submit = handleSubmit(async (values) => {
  submiting.value = true;
  const data = {
    name: values.site_name,
    client_id: props.clientId,
    areas: {
      data: [
        {
          name: values.site_name,
          meters: {
            data: getMetersData(values.pce, values.prm),
          },
        },
      ],
    },
  };
  if (props.site) {
    await updateSite(props.site.id, { name: values.site_name });
    await getClient(props.clientId);
  } else {
    await createSite(data);
  }
  submiting.value = false;
  modal.value = false;
});

function getMetersData(pce?: string, prm?: string) {
  const meters: { data_source_id: string; meter_type_id: number }[] = [];
  if (pce)
    meters.push({
      data_source_id: pce,
      meter_type_id: 2,
    });
  if (prm)
    meters.push({
      data_source_id: prm,
      meter_type_id: 1,
    });
  return meters;
}

// Lifecycle
watch(
  () => modal.value,
  () => {
    if (modal.value) {
      resetForm({
        values: {
          site_name: props.site?.name,
        },
      });
    }
  },
);
</script>
<template>
  <nrjx-modal v-model="modal" width="800" :title="title">
    <div class="grid">
      <ui-form-input-text
        :label="$t('global.site_name')"
        :placeholder="$t('global.site_name')"
        autocomplete="off"
        required
        name="site_name"
      />

      <ui-form-input-text
        v-if="!site"
        :label="$t('global.prm_id')"
        :placeholder="$t('global.prm_id')"
        autocomplete="off"
        required
        name="prm"
      />

      <ui-form-input-text v-if="!site" :label="$t('global.pce_id')" :placeholder="$t('global.pce_id')" autocomplete="off" name="pce" />
    </div>
    <template #footer>
      <ui-button :loading="submiting" @click="submit()">
        {{ site ? t('gloabal.edit_site') : t('gloabal.create_site') }}
      </ui-button>
    </template>
  </nrjx-modal>
</template>
