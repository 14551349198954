import type { TableAction } from '~/components/ui/data/DataTableActions.vue';

export const useSiteTable = () => {
  // Composables
  const { t } = useI18n();

  // Data
  const headers = [
    { label: t('global.name'), key: 'name' },
    { label: t('global.production_start_week_day'), key: 'production_start_weekday' },
    { label: '', key: 'actions', className: 'w-[80px]' },
  ];

  const actions: TableAction[] = [{ label: t('global.see'), icon: 'Eye', key: 'view' }];

  return { headers, actions };
};
