<script lang="ts" setup>
// Components
import UserList from '../../components/cards/userList.vue';
import ClientInformations from '../../components/cards/client/informations.vue';
import SiteList from '../../components/cards/site/list.vue';

// Composables
const route = useRoute();
const { t } = useI18n();
// Store
const { setCurrentClient } = adminStore();
const { currentClient } = storeToRefs(adminStore());

// Data
const loading = ref(false);
const title = computed(() => `Client ${route.params.id}`);
const breadcrumb = computed(() => [
  {
    name: t('global.admin'),
    url: '/admin',
  },
  {
    name: t('global.clients'),
    url: '/admin/clients',
  },
]);

// Composables
const { getClient } = useClientApi();

// Fetch client
onMounted(async () => {
  loading.value = true;
  const response = await getClient(parseInt(route.params.id as string));
  await setCurrentClient(response);
  loading.value = false;
});
</script>
<template>
  <ui-page :title="title" :breadcrumb="breadcrumb" :loading="loading" :error="!currentClient">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
      <client-informations class="col-span-1" />
      <site-list class="col-span-1" />
      <user-list class="col-span-2" />
    </div>
  </ui-page>
</template>
