<script lang="ts" setup>
import type { AdminApiGetClient } from '../../types';

// Components
import AdminUserModal from '../modal/UserModal.vue';

// Composables
const { headers, actions } = useUserTable();
const { currentClient } = adminStore();

// Data
const userModal = ref<boolean>(false);
const selectedUser = ref<AdminApiGetClient['clients_by_pk']['users'][0] | null>(null);

// Methods
const handleAction = async (event: string, item: AdminApiGetClient['clients_by_pk']['users'][0] | null) => {
  if (event === 'edit') {
    selectedUser.value = item;
    userModal.value = true;
  } else if (event === 'add') {
    selectedUser.value = item;
    userModal.value = true;
  }
};
</script>
<template>
  <ui-card :title="$t('global.users')">
    <template #header>
      <ui-button left-icon="Plus" @click="handleAction('add', null)">
        {{ $t('admin.add_user') }}
      </ui-button>
    </template>
    <ui-data-table :headers="headers" :items="currentClient?.users ?? []">
      <template #item-id="{ item }">
        <ui-badge color="blue">
          {{ item.id }}
        </ui-badge>
      </template>
      <template #item-first_name="{ item }">
        {{ item.first_name }}
      </template>
      <template #item-last_name="{ item }">
        {{ item.last_name }}
      </template>
      <template #item-email="{ item }">
        {{ item.email }}
      </template>
      <template #item-weekly_reporting="{ item }">
        <ui-badge :color="item.weekly_reporting ? 'blue' : 'gray'">
          {{ item.weekly_reporting ? $t('global.yes') : $t('global.no') }}
        </ui-badge>
      </template>
      <template #item-role="{ item }">
        <ui-badge :color="item.role === 'admin_app' ? 'red' : 'primary'">
          {{ item.role }}
        </ui-badge>
      </template>

      <template #item-actions="{ item }">
        <ui-data-table-actions :items="actions" @click-item="handleAction($event, item)" />
      </template>
    </ui-data-table>

    <admin-user-modal v-model="userModal" :user="selectedUser" :client-id="currentClient?.id" />
  </ui-card>
</template>
