import type { Header } from '~/components/ui/data/DataTable.vue';
import type { TableAction } from '~/components/ui/data/DataTableActions.vue';

export const useUserTable = () => {
  const { t } = useI18n();

  const headers: Header[] = [
    { label: t('global.ID'), key: 'id' },
    { label: t('global.first_name'), key: 'first_name' },
    { label: t('global.last_name'), key: 'last_name' },
    { label: t('global.email'), key: 'email' },
    { label: t('global.weekly_reporting'), key: 'weekly_reporting' },
    { label: t('global.role'), key: 'role' },
    { label: t('global.action'), key: 'actions' },
  ];

  const actions = computed((): TableAction[] => {
    return [
      {
        key: 'edit',
        label: t('global.edit'),
        icon: 'Pen',
        iconStokeWidth: 2,
      },
    ];
  });

  return { headers, actions };
};
