<script setup lang="ts">
import { useForm } from 'vee-validate';
import { useI18n } from 'vue-i18n';
import * as yup from 'yup';
import type { AdminApiGetClient, AdminApiGetUsersByClientId } from '../../types';

interface Form extends Partial<AdminApiGetUsersByClientId['users'][0]> {
  password: string;
}

// Composables
const { t } = useI18n();
const { showToast } = toastStore();
const { postUser } = useApi();
const { getClient } = useClientApi();

// Store
const { setCurrentClient } = adminStore();

// Props
const modal = defineModel<boolean>();
const props = defineProps<{
  clientId: number;
  user: AdminApiGetClient['clients_by_pk']['users'][0] | undefined;
}>();

// Data
const submiting = ref(false);

const { handleSubmit, resetForm, values } = useForm<Form>({
  validationSchema: yup.object({
    first_name: yup.string().required(t('global.first_name_is_required')),
    last_name: yup.string().required(t('global.last_name_is_required')),
    email: yup.string().when('showEmailPasswordField', {
      is: props.user !== undefined,
      then: (schema) => schema.required(t('global.email_is_required')),
    }),
    password: yup.string().when('showEmailPasswordField', {
      is: props.user !== undefined,
      then: (schema) => schema.required(t('global.password_is_required')),
    }),
    role: yup.string(),
  }),
});

const title = computed(() =>
  props.user ? t('manage_user.edit_user') + ` : ${props.user.first_name} ${props.user.last_name}` : t('manage_user.create_user'),
);
const roles = computed(() => {
  return [
    { key: 'admin_app', label: t('global.administrator') },
    { key: 'user', label: t('global.user') },
  ];
});

const genPassword = () => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const passwordLength = 16;
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  let password = '';
  while (!passwordRegex.test(password)) {
    password = '';
    for (let i = 0; i < passwordLength; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      password += chars[randomIndex];
    }
  }
  return password;
};

async function copyEmailToClipboard() {
  await navigator.clipboard.writeText(values.email);
  showToast({ type: 'success', title: t('global.success'), message: t('manage_user.form.email_copied') });
}
async function copyPasswordToClipboard() {
  await navigator.clipboard.writeText(values.password);
  showToast({ type: 'success', title: t('global.success'), message: t('manage_user.form.password_copied') });
}

const submit = handleSubmit(async (values) => {
  submiting.value = true;
  try {
    const data = {
      ...values,
      client_id: props.clientId,
    };

    if (props.user) {
      await postUser(props.user.id, data);
      const response = await getClient(props.clientId);
      await setCurrentClient(response);
      showToast({ type: 'success', title: t('global.success'), message: t('manage_user.form.user_edited') });
    } else {
      await postUser('new', data);
      showToast({ type: 'success', title: t('global.success'), message: t('manage_user.form.user_created') });
    }
    const response = await getClient(props.clientId);
    await setCurrentClient(response);
  } catch (error) {
    showToast({ type: 'error', title: t('global.error'), message: error.data.message });
    throw error;
  }
  submiting.value = false;
  modal.value = false;
});

watch(
  () => modal.value,
  () => {
    if (props.user && modal.value) {
      resetForm({
        values: {
          first_name: props.user?.first_name,
          last_name: props.user?.last_name,
          email: props.user?.email,
          role: props.user?.role,
        },
      });
    } else if (!props.user && modal.value) {
      resetForm({
        values: {
          first_name: '',
          last_name: '',
          email: '',
          role: 'user',
          password: genPassword(),
        },
      });
    } else {
      resetForm();
    }
  },
);
</script>

<template>
  <nrjx-modal v-model="modal" width="700" :title="title">
    <div class="grid">
      <!-- First Name -->
      <ui-form-input-text
        :label="$t('global.first_name')"
        :placeholder="$t('global.form.first_name_placeholder')"
        autocomplete="off"
        required
        name="first_name"
      />

      <!-- Last Name -->
      <ui-form-input-text
        :label="t('global.last_name')"
        :placeholder="t('global.form.last_name_placeholder')"
        autocomplete="off"
        required
        name="last_name"
      />

      <!-- Email -->
      <div v-if="!props.user" class="relative flex items-end gap-3">
        <ui-form-input-text :label="t('global.email')" :placeholder="t('global.form.email_placeholder')" autocomplete="off" name="email" />
        <div class="cursor-pointer mb-4" @click="copyEmailToClipboard()">
          <ui-button class="!py-2" color="secondary">
            <ui-icon name="Copy" :size="20" />
          </ui-button>
        </div>
      </div>

      <!-- Password -->
      <div v-if="!props.user" class="relative flex items-end gap-3">
        <ui-form-input-text
          :label="t('global.password')"
          :placeholder="t('global.form.password_placeholder')"
          autocomplete="off"
          required
          name="password"
        />
        <div class="cursor-pointer mb-4" @click="copyPasswordToClipboard()">
          <ui-button class="!py-2" color="secondary">
            <ui-icon name="Copy" :size="20" />
          </ui-button>
        </div>
      </div>

      <!-- Role -->
      <nrjx-input-select
        name="role"
        required
        :label="t('global.role')"
        :items="roles"
        fit-to-select
        item-key="key"
        item-label="label"
        :placeholder="t('global.form.role_placeholder')"
      />

      <!-- Weekly Reporting -->
      <nrjx-input-switch v-if="props.user" name="weekly_reporting" class="mt-4" :label="t('global.weekly_reporting')" />
    </div>
    <template #footer>
      <ui-button :loading="submiting" @click="submit()">
        {{ props.user ? t('manage_user.edit_user') : t('manage_user.create_user') }}
      </ui-button>
    </template>
  </nrjx-modal>
</template>
